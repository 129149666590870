<template lang="pug">
BasePane
  Accordion(fix)
    OmInput#video-url(v-model="videoUrl" small :label="$t('youtubeUrl')")
    Heading(expandableGap="12px" fix)
      template(#titleTrailing)
        .label {{ $t('autoplay') }}
      template(#prependEnding)
        OmSwitch#autoplay(v-model="autoplay")
      template(#help)
        span {{ $t('autoplayYoutubeTooltip') }}
    Heading(expandableGap="12px" fix v-if="!autoplay")
      template(#titleTrailing)
        .label {{ $t('controls') }}
      template(#prependEnding)
        OmSwitch#controls(v-model="controls")
    Align(v-model="align" :labelOverride="$t('align')")
    RangeInput(:label="$t('size')" v-model="size" :min="50" :max="1000" :step="5")
  hr.sidebar-spacing-y
  Accordion(fix)
    template(#title)
      .font-weight-bold {{ $t('spacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Margin
  hr.sidebar-spacing-y
  BackgroundAndBorder(
    shadow
    typeOfComponent="video"
    :deviceSelector="false"
    :label="$t('border')"
    :color="false"
  )
  hr.my-3
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import { mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import itemMixin from '@/editor/mixins/item';
  import BasePane from './BasePane.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      Accordion,
      Heading,
      Align,
      RangeInput,
      Margin,
      BasePane,
      Hide,
      BackgroundAndBorder,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['mobilePreview']),
      videoUrl: {
        get() {
          return this.getValueOf('data.videoUrl');
        },
        set(value) {
          this.setValueOf('data.videoUrl', value);
        },
      },
      autoplay: {
        get() {
          return this.getValueOf('data.autoPlay');
        },
        set(value) {
          this.setValueOf('data.autoPlay', value);
        },
      },
      controls: {
        get() {
          return this.getValueOf('data.controls');
        },
        set(value) {
          this.setValueOf('data.controls', value);
        },
      },
      align: {
        get() {
          return this.getValueOf('data.align');
        },
        set(value) {
          this.setValueOf('data.align', value);
        },
      },
      size: {
        get() {
          return Number(this.getValueOf('desktop.height'));
        },
        set(value) {
          this.setValueOf('desktop.height', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
    },
  };
</script>
